@import "colors";

.InfoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px $color-divider;
  padding: 0.4rem 0.25rem 0.4rem 0.25rem;
  text-decoration: none;
  &:last-child {
    border-bottom: none;
  }
  &.smallPrint {
    font-size: 0.9rem;
    .label {
      color: $color-info-row-value;
    }
  }
  &.title {
    padding: 0.4rem 0 0.4rem 0;
    border-bottom: none;
    .label {
      text-transform: uppercase;
      color: $color-info-row-title;
      font-size: 0.9rem;
    }
    .value {
      color: $color-info-row-title;
      font-family: sans-serif;
      font-size: 0.9rem;
    }
  }
  &.titleWithMargin {
    @extend .title;
    padding-top: 1.5rem;
  }
  &.boldTitle {
    padding: 0.4rem 0 0.5rem 0;
    border-bottom: none;
    .label {
      font-family: FagoProBold, serif;
      color: $color-info-row-title;
    }
    .value {
      color: $color-info-row-title;
      font-family: sans-serif;
      font-size: 0.9rem;
    }
  }
  .label {
    font-family: FagoPro, serif;
    color: $color-info-row-label;
    .subLabel {
      color: $color-info-row-title;
      font-size: 0.75em;
      line-height: 1em;
      margin-bottom: 0.2em;
    }
  }
  .value {
    text-align: right;
    color: $color-info-row-value;
    font-family: sans-serif;
    .chevron {
      margin-left: 0.5rem;
    }
    .subValue {
      color: $color-info-row-title;
      font-size: 0.75em;
      line-height: 1em;
      margin-bottom: 0.2em;
    }
  }
  &.link:hover {
    background-color: $color-info-row-hilite;
  }
}
