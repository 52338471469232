@import "colors";
@import "variables";

.Footer {
  background-color: $color-footer-bg;
  color: $color-footer-text;
  text-align: right;
  font-size: 13px;
  padding: 0 16px 0 16px;
  line-height: $footer-height - $footer-border-height;
  height: $footer-height;
  overflow: hidden;
  border-top: solid $footer-border-height $color-footer-border;
  .separator {
    margin: 0 0.5rem 0 0.5rem;
  }
  a {
    color: $color-footer-text;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .btn-link {
    color: $color-footer-text;
    margin: 0;
    padding: 0;
    font-size: 13px;
    border: none;
    margin-top: -4px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 576px) {
    display: none;
  }
  @media print {
    display: none;
  }
}
