@import "variables";
@import "colors";

@mixin box-shadow($top, $left, $blur, $size, $color) {
  -webkit-box-shadow: $top $left $blur $size $color;
  -moz-box-shadow: $top $left $blur $size $color;
  box-shadow: $top $left $blur $size $color;
}

@mixin gradient-background($direction, $start, $end) {
  background: -webkit-linear-gradient($direction, $start, $end);
  background: -moz-linear-gradient($direction, $start, $end);
  background: -o-linear-gradient($direction, $start, $end);
  background: linear-gradient($direction, $start, $end);
}

@mixin gradient-background-with-overlay($direction, $start, $end, $overlay) {
  background: $overlay, -webkit-linear-gradient($direction, $start, $end);
  background: $overlay, -moz-linear-gradient($direction, $start, $end);
  background: $overlay, -o-linear-gradient($direction, $start, $end);
  background: $overlay, linear-gradient($direction, $start, $end);
}

@mixin background-image-with-overlay-color($background, $color) {
  background: -webkit-linear-gradient(0deg, $color, $color), $background;
  background: -moz-linear-gradient(0deg, $color, $color), $background;
  background: -o-linear-gradient(0deg, $color, $color), $background;
  background: linear-gradient(0deg, $color, $color), $background;
}

@mixin contentContainer {
  .contentContainer {
    display: flex;
    justify-content: space-between;
    padding-bottom: $content-margin-bottom;
    .contentColumn {
      flex: 1;
      margin: 0 0 0 $content-margin-left;
      &:last-of-type {
        margin: 0 $content-margin-right 0 2rem;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      .contentColumn {
        margin: 0 $content-margin-right 0 $content-margin-left;
        &:last-of-type {
          margin: 1rem $content-margin-right 0 $content-margin-left;
        }
      }
    }
  }
}
