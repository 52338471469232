@import "variables";

.Salary {
  .buttonGroup {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .content {
    padding-bottom: $content-margin-bottom;
    flex: 1;
    margin-left: $content-margin-left;
    margin-right: $content-margin-right;
    .noSalariesSent {
      font-family: FagoProBold, serif;
    }
  }
  @media (max-width: 992px) {
    .content {
      margin: 0 $content-margin-right 0 $content-margin-left;
    }
  }
  @media (max-width: 576px) {
    .content {
      margin: $content-margin-top $content-margin-right 0 $content-margin-left;
    }
  }
  @media print {
    .content {
      margin: 0;
    }
  }
}
