.LanguageSelector {
  .localeSelector {
    margin-top: -1px;
    width: 50px;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 576px) {
      margin-top: 1px;
    }
    .icon {
      white-space: nowrap;
      color: $color-header-text;
      font-size: 1.37rem;
      transition: font-size 0.1s;
      .localeLabel {
        text-transform: uppercase;
        transition: font-size 0.1s;
        font-size: 1.12rem;
        padding-right: 3px;
      }
    }
    .icon:hover {
      font-size: 1.56rem;
      cursor: pointer;
      transition: font-size 0.1s;
      .localeLabel {
        transition: font-size 0.1s;
        font-size: 1.25rem;
        padding-right: 3px;
      }
    }
  }
}
