@import "colors";

.AuthMethodModal {
  .intro {
    font-family: FagoProBold, serif;
    font-size: 1.1rem;
  }
  .methods {
    text-align: center;
    margin-top: 16px;
    a {
      display: inline-block;
      margin: 5px 3px 5px 3px;
      height: 70px;
      border: solid 1px #fff;
      border-radius: 8px;
      &:hover {
        border: solid 1px $color-btn-primary-bg;
      }
      @media (max-width: 576px) {
        height: 55px;
        margin: 0;
      }
      img {
        height: 70px;
        &:hover {
          cursor: pointer;
        }
        @media (max-width: 576px) {
          height: 55px;
        }
      }
    }
  }
}

#MethodOMASAASTOPANKKI {
  img {
    height: 40px;
    margin: 15px 10px 15px 10px;
    @media (max-width: 576px) {
      height: 35px;
      margin: 8px 10px 8px 10px;
    }
  }
}
