@import "colors";

.SideMenu {
  max-width: 200px;
  background-color: $color-side-menu-bg;
  .item {
    background-color: $color-side-menu-item-bg;
    margin: 0;
    padding: 0;
    border-radius: 0;
    width: 100%;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: solid 1px $color-side-menu-item-border;
    color: $color-side-menu-item-text;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
    text-decoration: none;
    .text {
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .icon {
      margin-left: 6px;
      margin-right: 8px;
      font-size: 20px;
      color: $color-side-menu-item-text;
      &.terms {
        font-size: 18px;
        margin-right: 10px;
      }
    }
    &:hover {
      background-color: $color-side-menu-item-hover;
      cursor: pointer;
    }
    &.selected {
      color: $color-side-menu-item-selected;
      .icon {
        color: $color-side-menu-item-selected;
      }
    }
  }
}
