.FormDatePicker {
  .react-date-picker {
    width: 100%;
    .react-date-picker__wrapper {
      border-radius: 0.375rem;
      border-color: #ced4da;
      padding: 0.3rem 0.5rem 0.3rem 0.75rem;
      color: #212529;
      @media (max-width: 576px) {
        padding: 0 0.5rem 0 0.75rem;
      }
    }
  }
  &.error {
    .react-date-picker {
      .react-date-picker__wrapper {
        border-color: rgb(220, 53, 69);
      }
    }
  }
  .errorMessage {
    display: block;
    color: rgb(220, 53, 69);
    margin-top: 0.25rem;
    font-size: 0.875em;
  }
}
