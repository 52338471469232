@import "variables";
@import "mixins";

.Absences {
  .newButton {
    margin-left: $content-margin-left;
    margin-right: $content-margin-left;
    .newButtonIcon {
      font-size: 1.3rem;
      margin-right: 0.3rem;
      margin-top: -0.1rem;
      margin-left: -0.3rem;
    }
  }
  @include contentContainer;
}
