@import "colors";

.ProgressBar {
  border-bottom: solid 1px $color-divider;
  padding: 0.4rem 0.25rem 0.4rem 0.25rem;
  .title {
    font-family: FagoPro, sans-serif;
    margin: 0 0 0.2rem 0;
  }
  .progress-bar {
    &.low {
      background-color: $color-progress-bar-low;
    }
    &.medium {
      background-color: $color-progress-bar-medium;
    }
    &.high {
      background-color: $color-progress-bar-high;
    }
  }
  .label {
    display: inline-block;
    position: relative;
    font-weight: bold;
    font-family: sans-serif;
    &.low {
      color: $color-progress-bar-low;
    }
    &.medium {
      color: $color-progress-bar-medium;
    }
    &.high {
      color: $color-progress-bar-high;
    }
  }
}
