@import "colors";
@import "variables";

.Menu .divider {
  display: none;
  @media (max-width: 576px) {
    display: block;
    height: $menu-divider-height;
    background-color: $silta-dark-red;
    margin: 0 10px 0 10px;
  }
}

.Menu nav {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  .item {
    background-color: transparent;
    margin: 0;
    padding: 0;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: solid 1px $color-menu-item-border;
    color: $color-menu-text;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
    text-decoration: none;
    .text {
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .icon {
      margin-left: 16px;
      margin-right: 10px;
      font-size: 20px;
      width: 20px;
      color: $color-menu-text;
    }
    &.salaries {
      .icon {
        path {
          stroke: $color-menu-item-selected-sm;
          stroke-width: 4;
          fill: $color-menu-text;
        }
      }
    }
    &.tax_info {
      .icon {
        width: 22px;
        margin-right: 8px;
        path {
          stroke: $color-menu-item-selected-sm;
          stroke-width: 2;
          fill: $color-menu-text;
        }
      }
    }
    &.onlyInSmall {
      display: none;
    }
    @media (max-width: 576px) {
      color: $color-menu-item-sm;
      border-bottom: none;
      flex: 1;
      height: $menu-height;
      .icon {
        color: $color-menu-item-sm;
      }
      &.salaries {
        .icon {
          path {
            stroke: $color-menu-item-sm;
            stroke-width: 4;
            fill: $color-menu-text;
          }
        }
      }
      &.tax_info {
        .icon {
          path {
            stroke: $color-menu-item-sm;
            stroke-width: 1;
            fill: $color-menu-text;
          }
          margin-bottom: 2px;
        }
      }
      &.hideInSmall {
        display: none;
      }
      &.onlyInSmall {
        display: flex;
      }
      &.selected {
        .text {
          color: $color-menu-item-selected-sm;
        }
        .icon {
          color: $color-menu-item-selected-sm;
          path {
            stroke: $color-menu-item-selected-sm;
          }
        }
      }
    }
    @media (max-width: 992px) {
      height: auto;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      .text {
        font-size: 10px;
        margin-top: 4px;
        margin-bottom: 10px;
      }
      .icon {
        margin: 10px 0 0 0;
      }
    }
  }
  .item:hover {
    background-color: $color-menu-item-hover;
    cursor: pointer;
  }
  .item.selected {
    background-color: $color-menu-item-selected;
  }
  @media (max-width: 576px) {
    margin-top: 0;
    flex-direction: row;
    justify-content: space-evenly;
  }
}
