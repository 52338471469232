@import "colors";

.SalaryDetailsSection {
  .sectionTitle {
    display: flex;
    justify-content: space-between;
    font-family: FagoProBold, serif;
    font-size: 1.1rem;
    color: $color-salary-section-title;
    margin: 1rem 0 0.5rem 0;
    .title {
    }
    .total {
      font-family: sans-serif;
      font-weight: bold;
    }
  }
}
