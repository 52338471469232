// --vh gets set in index.html script
$vh: calc(var(--vh, 1vh) * 100);

$header-height-lg: 60px;
$header-height-sm: 46px;
$header-height-landing-sm: 200px;
$header-border-height: 2px;

$menu-width-lg: 210px;
$menu-width-sm: 110px;
$menu-height: 60px;
$menu-divider-height: 2px;

$footer-height: 30px;
$footer-border-height: 2px;

$content-margin-top: min(calc($vh * 0.015), 40px);
$content-margin-right: min(3vw, 40px);
$content-margin-bottom: min(calc($vh * 0.02), 50px);
$content-margin-left: min(3vw, 40px);
