@import "./colors";
@import "./variables";
@import "./mixins";

.Header {
  position: relative;
  display: flex;
  flex: 0 0 $header-height-lg;
  background-color: $color-header-bg;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid $header-border-height $color-header-border;
  z-index: 100;
  // Drop shadow
  &::after {
    display:    block;
    content:    '';
    position:   absolute;
    width:      100%;
    height:     12px;
    bottom:     -12px;
    left:       0;
    @include gradient-background(180deg, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0) 100%);
  }
  .logo {
    margin-left: min(3vw, 24px);
    display: inline-block;
    height: calc($header-height-lg * 0.5);
    flex: 0;
  }
  .envName {
    font-size: 1.2rem;
    //font-family: FagoProBold, serif;
    color: $color-header-text;
    position: relative;
    top: 4px;
    left: 1.5rem;
  }
  .title {
    //font-family: FagoPro, serif;
    font-size: 1.4rem;
    line-height: 1.4rem;
    strong {
      //font-family: FagoProBold, serif;
    }
    color: $color-header-text;
    margin-left: 1.5rem;
    position: relative;
    top: 4px;
  }
  &.landing {
    .title {
      display: none;
    }
    &::after {
      display: none;
    }
    border-bottom: none;
  }
  .signOutContainer {
    display: flex;
    align-items: center;
    margin-right: min(3vw, 12px);
    a.username {
      position: relative;
      top: 1px;
      color: $color-header-text;
      text-decoration: none;
      font-size: 1rem;
      transition: font-size 0.1s;
      &:hover {
        font-size: 1.075rem;
        transition: font-size 0.1s;
      }
      @media (max-width: 576px) {
        display: none;
      }
    }
    .buttonContainer {
      @media print {
        display: none;
      }
      display: flex;
      align-items: center;
      width: 90px;
      justify-content: right;
      margin-left: 20px;
      margin-right: 8px;
      @media (max-width: 576px) {
        width: 75px;
        margin-left: 10px;
      }
      .button {
        border: 0;
        background: none;
        display: block;
        margin: 0;
        padding: 0;
      }
      .iconContainer {
        width: 38px;
        display: flex;
        justify-content: right;
        .icon {
          color: $color-header-text;
          font-size: 1.37rem;
          transition: font-size 0.1s;
        }
        .icon:hover {
          font-size: 1.56rem;
          cursor: pointer;
          transition: font-size 0.1s;
        }
      }
    }
  }
  @media (max-width: 992px) {
    flex: 0 0 $header-height-sm;
    .logo {
      height: $header-height-sm * 0.5;
    }
  }
  @media (max-width: 576px) {
    &:after {
      display: none;
    }
    &.landing {
      border: none;
      flex: 0 0 $header-height-landing-sm;
      flex-direction: column-reverse;
      background-color: transparent;
      .logoContainer {
        padding-bottom: calc(150px - 30vw);
        .logo {
          margin: 0 0 2vw 0;
          display: block;
          width: 20vw;
          height: auto;
        }
        .envName {
          display: none;
        }
      }
      .signOutContainer {
        width: 100%;
        justify-content: flex-end;
        height: 40px;
      }
      .title {
        margin-left: 0;
        font-size: 6vw;
      }
    }
  }
}
