@import "variables";
@import "colors";

.Terms {
  .content {
    margin: 0 $content-margin-right $content-margin-bottom $content-margin-left;
    .itemSection {
      .itemHeading {
        font-family: FagoProBold, serif;
        font-size: 1.1rem;
        color: $color-info-row-title;
      }
      .itemContent {
        margin-top: 1.0rem;
        margin-bottom: 1.8rem;
      }
    }
    .actions {
      display: flex;
      justify-content: end;
      margin-bottom: 40px;
      .btn {
        margin-left: 20px;
      }
    }
  }
}
