@import "colors";
@import "variables";
@import "mixins";

.Main {
  display: flex;
  width: 100%;
  .menuContainer {
    @include gradient-background(180deg, $color-menu-bg-start 0 40%, $color-menu-bg-end 100%);
    // background-color: $color-menu-bg;
    flex: 0 0 $menu-width-lg;
  }
  .mainContainer {
    //background-color: $color-content-bg;
    @include gradient-background(180deg, $color-content-top-bg 0 65px, $color-content-bg 65px 100%);
    background-attachment: local;
    text-align: left;
    flex: 1;
    /*overflow-y: auto;
    overflow-x: hidden;*/
    overflow: hidden;
    min-height: calc($vh * 0.8);
  }
  .termsContainer {
    background-color: $color-content-bg;
    text-align: left;
    flex: 1;
    overflow: hidden;
  }
  .landingContainer {
    background-color: $color-landing-bg;
    background-image: url("../images/desktopBackground.jpg");
    background-size: cover;
    text-align: left;
    flex: 1;
    overflow: hidden;
    min-height: calc($vh * 0.8);
  }
  @media (max-width: 1200px) {
    .mainContainer, .landingContainer {
      overflow-y: auto;
      overflow-x: hidden;
      height: calc($vh - $header-height-lg - $footer-height);
    }
  }
  @media (max-width: 992px) {
    .menuContainer {
      flex: 0 0 $menu-width-sm;
    }
    .mainContainer, .landingContainer {
      overflow-y: auto;
      overflow-x: hidden;
      height: calc($vh - $header-height-sm - $footer-height);
    }
  }
  @media (max-width: 576px) {
    flex-direction: column-reverse;
    .menuContainer {
      background: $color-menu-bg-sm;
      flex: 0 0 auto;
    }
    .mainContainer {
      overflow-y: auto;
      overflow-x: hidden;
      min-height: calc($vh - $menu-height - $header-height-sm - $menu-divider-height);
      max-height: calc($vh - $menu-height - $header-height-sm - $menu-divider-height);
      //@include gradient-background(180deg, $color-content-top-bg 0 65px, $color-content-bg 65px 100%);
      //background-attachment: local;
    }
    .landingContainer {
      overflow-y: auto;
      overflow-x: hidden;
      min-height: calc($vh - $header-height-landing-sm);
      max-height: calc($vh - $header-height-landing-sm);
      background-color: transparent;
      background-image: none;
    }
  }
  @media print {
    .menuContainer {
      display: none;
    }
    .mainContainer {
      height: auto;
      min-height: auto;
      max-height: none;
      overflow-y: visible;
    }
  }
}
