@import "colors";

.Expandable {
  .toggleButton {
    display: flex;
    justify-content: space-between;
    padding: 0.4rem 0 0.4rem 0;
    width: 100%;
    border: none;
    background-color: transparent;
    border-bottom: solid 1px $color-divider;
    &:hover {
      background-color: $color-info-row-hilite;
    }
    .title {
      font-family: FagoProBold, serif;
      color: $color-info-row-title;
      margin-left: 0.25rem;
    }
    .rightContainer{
      display: flex;
      .subtitle {
        margin-right: 0.25rem;
        font-family: sans-serif;
        color: $color-info-row-value;
      }
      .arrow {
        margin-left: 0.6rem;
        transition: all 0.3s ease-out;
        margin-right: 0.25rem;
      }
    }
  }
  .expandableContent {
    padding: 0 0.5rem 0 0.5rem;
    background-color: $color-expandable-bg;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out, padding 0.25s ease-in;
  }
  &.open {
    .expandableContent {
      padding: 0.5rem 0.5rem 1rem 0.5rem;
      max-height: 5000px;
      transition: max-height 5s ease-out;
      border-bottom: solid 1px $color-divider;
    }
    .toggleButton {
      .title {
      }
      .rightContainer{
        .arrow {
          transform: rotate(180deg);
          transition: all 0.3s ease-out;
        }
      }
    }
  }
  @media print {
    .expandableContent {
      padding: 0.5rem 0.5rem 1rem 0.5rem;
      max-height: none;
      border-bottom: solid 1px $color-divider;
    }
    .rightContainer{
      .arrow {
        display: none;
      }
    }
  }
}
