@import "variables";
@import "colors";

.PageTitle {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: $color-title-bg-lg;
  margin: 20px $content-margin-right 20px $content-margin-left;
  border: solid 1px $color-title-border;
  height: 6.5rem;
  @media (max-width: 512px) {
    height: 5.5rem;
  }
  .title {
    width: 100%;
    @media print {
      padding: 10px 0 10px 16px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.9rem;
    line-height: 1.9rem;
    padding: $content-margin-top $content-margin-right calc($vh * 0.01) $content-margin-left;
    color: $color-title-text-lg;
    a {
      text-decoration: none;
      color: $color-title-text-lg;
      &:hover {
        color: mix(#fff, $color-title-text-lg, 25%);
      }
    }
    @media (max-width: 992px) {
      color: $color-title-text-sm;
      font-size: 1.25rem;
      // white-space: nowrap;
      padding: 0 $content-margin-right 0 $content-margin-left;
      line-height: 1.4rem;
      justify-content: center;
      a {
        text-decoration: none;
        color: $color-title-text-sm;
      }
      @media print {
        padding: 0 0 0 16px;
      }
    }
  }
  .icon {
    &:last-of-type {
      display: none;
    }
    display: block;
    color: $color-title-text-sm;
    width: calc(6rem * 0.75);
    font-size: calc(3.9rem * 1);
    line-height: 0;
    margin: 0 1rem 0 1rem;
    @media (max-width: 992px) {
      width: 6rem;
      font-size: 3.9rem;
      &:last-of-type {
        display: block;
      }
    }
    @media (max-width: 512px) {
      margin: 0 0.7rem 0 1rem;
      width: calc(6rem * 0.9);
      font-size: calc(3.9rem * 0.9);
    }
    svg path {
      stroke: $color-title-text-sm;
    }
  }
}
