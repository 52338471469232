.BackButton {
  svg {
    margin-right: 0.5rem;
    margin-top: -0.1rem;
  }
  &.btn-sm {
    min-height: 2rem;
  }
  @media (min-width: 992px) {
    &.sm {
      display: none;
    }
  }
  @media (max-width: 992px) {
    &.md {
      display: none;
    }
  }
}
