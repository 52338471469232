@import "colors";
@import "variables";
@import "mixins";

.Landing {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .Notification {
    position: absolute;
    top: calc($vh * 0.05);
    z-index: 200;
    @media (max-width: 576px) {
      top: calc($vh * -0.1);
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 0 30px 0;
  }
  .actions {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    margin-top: 140px;
    .actionButton {
      text-transform: uppercase;
      text-decoration: none;
      text-align: center;
      margin: min(2vw, 15px);
      display: block;
      padding: 0 1.75rem 0 1.75rem;
      white-space: nowrap;
      height: 3rem;
      background-color: $color-landing-button-bg;
      color: $color-landing-button-text;
      font-size: 1.2rem;
      border-radius: 1.5rem;
      box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.2);
      &:hover {
        background-color: mix(white, $color-landing-button-bg, 20%);
      }
    }
  }
  .slogan {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: min(15vw, 6rem);
    span {
      color: $color-landing-button-text;
      font-size: min(7vw, 3rem);
      line-height: min(7vw, 3rem);
      white-space: nowrap;
    }
    .sloganBorder {
      border: solid 2px $color-landing-button-text;
      border-radius: 2px;
      width: 45%;
    }
  }
  .logoContainer {
    .logo {
      margin: 0 0 1rem 0;
      display: block;
      width: min(20vw, 160px);
      height: auto;
    }
    .title {
      text-align: center;
      display: block;
      font-size: 2rem;
      color: $color-header-text;
    }
    margin: 0 0 4rem 0;
  }
  @media (max-width: 576px) {
    position: absolute;
    width: 100%;
    height: initial;
    bottom: 0;
    .column {
      flex: 1 1 auto;
      padding: 40px 5% 0 5%;
      img {
        width: 100%;
      }
    }
    img {
      display: none;
    }
    .logoContainer {
      display: none;
    }
    .actions {
      width: 100%;
      margin: 0 0 4rem 0;
      flex-direction: column-reverse;
      align-items: center;
      .actionButton {
        margin: 0 0 2rem 0;
        width: 200px;
      }
    }
    .slogan {
      margin-bottom: calc($vh * 0.11);
      height: calc($vh * 0.15);
      .sloganBorder {
        border: solid 2px $color-landing-button-text;
        border-radius: 2px;
        width: 30%;
      }
    }
  }
}

#StartSimoButton {
  background-color: $color-landing-button-text;
  color: $color-landing-button-bg;
  &:hover {
    background-color: mix(white, $color-landing-button-bg, 80%);
  }
}

#StartDemoButton {
  margin: 4px;
  padding: 6px 12px 6px 12px;
  border: solid 1px $color-btn-primary-bg;
  background-color: $color-btn-primary-bg;
  border-radius: 0.375rem;
  color: $color-btn-primary-text;
  text-decoration: none;
  &:hover {
    background-color: mix(black, $color-btn-primary-bg, 20%);
  }
}
