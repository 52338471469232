@import "colors";

.SalaryPeriodSummaryList {
  .nextSalary {
    display: block;
    cursor: pointer;
    padding: 1rem;
    background-color: $color-next-salary-bg;
    color: $color-next-salary-text;
    margin-bottom: 0.5rem;
    text-decoration: none;
    &:hover {
      background-color: $color-next-salary-bg-hilite;
    }
    .salaryTotalContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        min-width: 20%;
      }
      .arrow {
        text-align: right;
        font-size: 1.8rem;
        margin-top: -1rem;
      }
      .salaryTotal {
        font-family: sans-serif;
        font-size: 2rem;
      }
      .salaryTotalPayday {
        font-family: FagoProBold, serif;
      }
    }
    .companyName {
      text-align: right;
    }
  }
}
