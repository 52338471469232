@import "colors";

.AbsenceRow {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px $color-divider;
  padding: 0.4rem 0.25rem 0.4rem 0.25rem;
  &:last-child {
    border-bottom: none;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    .column {
      flex: 1;
      &.period {
        flex: 2 1 0;
      }
      &.sentAt {
        flex: 1 1 0;
      }
      .label {
        font-family: FagoPro, serif;
        color: $color-info-row-label;
        font-size: 0.9rem;
        white-space: nowrap;
      }
      .value {
        color: $color-info-row-title;
        font-family: sans-serif;
        font-size: 0.9rem;
        white-space: nowrap;
        &.companyName {
          font-family: FagoProBold, serif;
        }
      }
    }
  }
}
