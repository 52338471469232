$silta-black: #000;
$silta-red: #ed1846;
$silta-dark-red: #500b1b;
$silta-white: #fefefe;
$silta-gray: #666;
$silta-light-gray: #f6f6f6;
$silta-turquoise: #3fc1be;
$silta-yellow: #ffd503;
$silta-peach: #f6b7a6;
$silta-green: #00aa00;

$color-landing-bg-start: $silta-red;
$color-landing-bg-end: $silta-dark-red;

$color-divider: #ddd;

$color-btn-primary-bg: $silta-red;
$color-btn-primary-text: $silta-white;
$color-btn-primary-disabled: $silta-gray;

$color-header-bg: $silta-red;
$color-header-border: mix(black, $silta-red, 10%);
$color-header-text: $silta-white;

$color-menu-bg: $silta-gray;
$color-menu-bg-start: $silta-red;
$color-menu-bg-end: $silta-dark-red;
$color-menu-bg-sm: $silta-white;
$color-menu-item-border: rgba(0, 0, 0, 0.1);
$color-menu-item-hover: rgba(255, 255, 255, 0.1);
$color-menu-item-selected: rgba(255, 255, 255, 0.1);
$color-menu-text: $silta-white;
$color-menu-item-sm: $silta-black;
$color-menu-item-selected-sm: $silta-red;
$color-menu-divider: $silta-dark-red;

$color-side-menu-bg: $silta-light-gray;
$color-side-menu-item-bg: transparent;
$color-side-menu-item-text: $silta-black;
$color-side-menu-item-hover: rgba(0, 0, 0, 0.05);
$color-side-menu-item-selected: $silta-red;
$color-side-menu-item-border: $silta-gray;

$color-content-top-bg: $silta-peach;
$color-content-bg: $silta-white;
$color-content-icon: $silta-white;

$color-footer-bg: $silta-red;
$color-footer-text: $silta-white;
$color-footer-border: mix(black, $silta-red, 10%);

$color-progress-bar-low: $silta-green;
$color-progress-bar-medium: $silta-yellow;
$color-progress-bar-high: $silta-red;

$color-title-bg-lg: $silta-white;
$color-title-text-lg: $silta-red;
$color-title-bg-sm: $silta-white;
$color-title-text-sm: $silta-red;
$color-title-border: $silta-black;

$color-info-row-title: $silta-gray;
$color-info-row-label: $silta-black;
$color-info-row-value: $silta-gray;
$color-info-row-hilite: $silta-light-gray;

$color-expandable-bg: $silta-light-gray;

$color-next-salary-bg: $silta-red;
$color-next-salary-bg-hilite: mix(white, $silta-red, 20%);
$color-next-salary-text: $silta-white;

$color-salary-section-title: $silta-red;

$color-landing-bg: rgba(255, 255, 255, 0.75);
$color-landing-subtitle: $silta-gray;
$color-landing-divider: $silta-red;
$color-landing-button-bg: $silta-red;
$color-landing-button-text: $silta-white;
