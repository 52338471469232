@import "mixins";

.CookieConsentDialog {
  position: absolute;
  bottom: 100px;
  left: 100px;
  z-index: 1000;
  @media (max-width: 1200px) {
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40%;
    width: 90%;
    max-width: 600px;
  }
  .alert {
    @include box-shadow(0,0,12px,0, rgba(0,0,0,0.3));
    text-align: left;
    .actions {
      text-align: right;
      .btn {
        margin-left: 6px;
      }
    }
  }
}
