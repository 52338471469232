@import "mixins";
@import "variables";

.App {
  text-align: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: calc($vh * 0.03);
  margin-bottom: calc($vh * 0.03);
  @include box-shadow(0,0,12px,0, rgba(0,0,0,0.3));
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    margin: 0;
    height: $vh;
  }
}
