@font-face {
  font-family: Dosis;
  src: url(./fonts/Dosis-Regular.ttf);
}

@font-face {
  font-family: DosisBold;
  src: url(./fonts/Dosis-Bold.ttf);
}

@font-face {
  font-family: FagoPro;
  src: url(./fonts/FagoPro.otf);
}

@font-face {
  font-family: FagoProBold;
  src: url(./fonts/FagoPro-Bold.otf);
}

@import "./styles/App";
@import "./styles/Header";
@import "./styles/Main";
@import "./styles/Menu";
@import "./styles/SideMenu";
@import "./styles/Landing";
@import "./styles/Footer";
@import "./styles/TaxInfo";
@import "./styles/TaxInfoCard";
@import "./styles/ProgressBar";
@import "./styles/PageTitle.scss";
@import "./styles/Loader.scss";
@import "./styles/InfoRow.scss";
@import "./styles/UserInfo.scss";
@import "./styles/Expandable.scss";
@import "./styles/Absences.scss";
@import "./styles/FormDatePicker.scss";
@import "./styles/SubmitButton.scss";
@import "./styles/Salaries.scss";
@import "./styles/Salary.scss";
@import "./styles/SalariesYear.scss";
@import "./styles/SalarySummaryList";
@import "./styles/SalaryDetailsList";
@import "./styles/SalaryDetailsSection";
@import "./styles/BackButton.scss";
@import "./styles/LanguageSelector.scss";
@import "./styles/Terms.scss";
@import "./styles/AuthMethodModal.scss";
@import "./styles/Redirect.scss";
@import "./styles/CookieConsentDialog.scss";
@import "./styles/Notification.scss";
@import "./styles/AbsenceRow.scss";
@import "./styles/mixins.scss";
@import "./styles/colors.scss";

html {
  font-size: 16px;
  @media (max-width: 992px) {
    background-color: $color-landing-bg-end;
    font-size: 14px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
  }
}

body {
  background-color: #666;
  background-image: url('./images/bodyBg.png');
  background-repeat: repeat;
  background-attachment: fixed;
  margin: 0;
  font-family: Dosis, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (max-width: 992px) {
    background-color: $color-landing-bg-end;
  }
  @media (max-width: 576px) {
    @include gradient-background-with-overlay(180deg, $color-landing-bg-start 0 40%, $color-landing-bg-end 100%, url('./images/mobileLandingHeader.png'));
    background-repeat: no-repeat;
    background-size: contain;
  }
  @media print {
    background-image: none;
    background-color: #fff;
  }
}

button.btn.btn-primary {
  background: $color-btn-primary-bg;
  border: 1px solid $color-btn-primary-bg;
  color: $color-btn-primary-text;
  &:hover {
    background: mix(black, $color-btn-primary-bg, 10%);
  }
}

button.btn.btn-outline-primary {
  background-color: transparent;
  border: 1px solid $color-btn-primary-bg;
  color: $color-btn-primary-bg;
  &:focus {
    border: 1px solid $color-btn-primary-bg;
    color: $color-btn-primary-bg;
    box-shadow: none;
  }
  &:hover {
    background: mix(black, $color-btn-primary-bg, 10%);
    color: $color-btn-primary-text;
  }
  &[disabled] {
    border: 1px solid $color-btn-primary-disabled;
    color: $color-btn-primary-disabled;
  }
}

form {
  .row {
    margin-bottom: 0.25rem;
    .col {
      .form-label {
        margin-top: 0.5rem;
      }
    }
  }
}

strong {
  font-family: DosisBold, serif;
  font-weight: initial;
}

code {
  color: $color-btn-primary-bg;
}

@media print {
  .btn {
    display: none;
  }
}
