@import "colors";
@import "variables";

.SalaryDetailsList {
  .salaryOverview {
    display: block;
    padding: 1rem;
    background-color: $color-next-salary-bg;
    color: $color-next-salary-text;
    margin-bottom: 1rem;
    text-decoration: none;
    .salaryOverviewTopRow {
      display: flex;
      justify-content: space-between;
    }
    .salaryTotalContainer {
      margin-top: -0.5rem;
      margin-bottom: -0.25rem;
      .salaryTotal {
        font-family: sans-serif;
        font-size: 2rem;
        text-align: center;
      }
      .salaryTotalPayday {
        font-family: FagoProBold, serif;
        text-align: center;
      }
    }
    .companyName {
      text-align: right;
    }
  }
  .salaryColumnContainer {
    display: flex;
    justify-content: space-between;
    .salaryColumn {
      flex: 1;
      &:nth-of-type(1) {
        margin: 0 1rem 0 0;
      }
      &:nth-of-type(2) {
        margin: 0 0 0 1rem;
      }
    }
    @media (max-width: 576px) {
      display: block;
      .salaryColumn {
        &:nth-of-type(1) {
          margin: 0;
        }
        &:nth-of-type(2) {
          margin: 0;
        }
      }
    }
    @media print {
      display: flex;
      .salaryColumn {
        flex: 1;
        &:nth-of-type(1) {
          margin: 0 1rem 0 0;
        }
        &:nth-of-type(2) {
          margin: 0 0 0 1rem;
        }
      }
    }
  }
  #DownloadPdfButton {
    padding: 0;
  }
}
